import React from 'react'
import { Image } from 'react-bootstrap'

const AboutScreen = () => {
	return (
		<section className='container py-5'>
			<h1 className='text-center'>OUR STORY</h1>

			<div className='text-center'>
				<hr className='w-25' />
			</div>

			<br />

			<div className='row'>
				<div className='col-12 col-sm-12 col-md-6'>
					<br />
					<div className='geeks'>
						<Image src='/images/about-1.png' alt='logo' fluid />
					</div>
				</div>
				<div className='col-12 col-sm-12 col-md-6'>
					<br />
					<ul>
						<li>
							<b>1881 : </b>The story goes that the estate of olive trees of the Baldi
							family already existed in 1881, in the Kairouan region Tunisia during
							the French protectorate establishment.
						</li>

						<li className='pt-4'>
							<b>1956 : </b>The new laws, allowed colonial forces to take control of
							agriculture farms. Despite all this, the Baldi family has not left.
						</li>

						<li className='pt-4'>
							<b>1980 : </b>Generations who inherited this situation, had to wait for
							the country independence in 1956 to launch legal battles to recover what
							was taken to them by force. It took until 1980 when they won their case.
						</li>
					</ul>
				</div>
			</div>

			<br />

			<br />

			<div className='row'>
				<div className='col-12 col-sm-12 col-md-6'>
					<br />
					<ul>
						<li>
							<b>2000 : </b>From 2000, the two older brothers Fathi and Lotfi began to
							modernize and innovate by installing a modern oil mill. In the same
							year, the Domaine Baldi obtained organic certification.
						</li>

						<li className='pt-4'>
							<b>2017 : </b>The Domaine Baldi launched a new brand of bottled olive
							oil.
						</li>
					</ul>
				</div>

				<div className='col-12 col-sm-12 col-md-6'>
					<br />
					<div className='geeks'>
						<Image src='/images/about-2.png' alt='logo' fluid />
					</div>
				</div>
			</div>

			<br />
			<br />

			<div className='text-center'>
				<a href='BROCHURE.pdf' target='blank' className='myBtn myWarning'>
					Download Details
				</a>
			</div>
		</section>
	)
}

export default AboutScreen
