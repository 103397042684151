import React from 'react'
import { Image } from 'react-bootstrap'

const AwardsAndCertificatesScreen = () => {
	return (
		<>
			<div className='container pt-5'>
				<br />

				<h1 className='text-center'>
					AWARDS OF PRESTIGIOUS INTERNATIONAL <br /> OLIVE OIL COMPETITIONS
				</h1>
				<hr style={{ width: '100px' }} />

				<br />

				<div className='container text-center'>
					<div className='row row-cols-2 row-cols-sm-2 row-cols-md-5 row-cols-lg-5 g-4 justify-content-center'>
						<Image src='/images/awards/1.png' alt='logo' fluid className='col mb-2' />

						<Image src='/images/awards/2.png' alt='logo' fluid className='col mb-2' />

						<Image src='/images/awards/3.png' alt='logo' fluid className='col mb-2' />

						<Image src='/images/awards/4.png' alt='logo' fluid className='col mb-2' />

						<Image src='/images/awards/5.png' alt='logo' fluid className='col mb-2' />
					</div>
				</div>

				<br />
				<br />
				<br />

				<p className='text-center' style={{ fontSize: '1.1rem' }}>
					In 2021, Domaine Beldi olive oil received two prestigious awards in the quality
					<br />
					category: LIOOC 2021, London International Olive oil Competitions and СIOOC
					2021,
					<br />
					Canada International Olive oil Competitions
				</p>
			</div>

			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
				<path
					fill='#FFFEEF'
					fill-opacity='1'
					d='M0,224L48,202.7C96,181,192,139,288,138.7C384,139,480,181,576,202.7C672,224,768,224,864,197.3C960,171,1056,117,1152,112C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
				></path>
			</svg>

			<div className='py-5' style={{ backgroundColor: '#FFFEEF' }}>
				<h1 className='text-center'>ORGANIC/BIO CERTIFICATIONS </h1>
				<hr style={{ width: '100px' }} />

				<p className='text-center'>
					Domaine Beldi olive oil is produced in compliance with the strict requirements
					of the codes of international
					<br />
					standards IOC (International Olive Council) and Codex Alimentarius. This
					guarantees the high quality of the oil
					<br />
					with low acidity, corresponding to the Organic / Bio class, which is confirmed
					by certificates: ECOCERT; AB;
					<br />
					Euro-leaf; USDA Organic
				</p>

				<div className='text-center'>
					<Image src='/images/awards-3.png' alt='logo' fluid />
				</div>

				<br />

				<div className='container text-center'>
					<div className='row'>
						<div className='col'>
							<Image src='/images/awards-4.png' alt='logo' fluid />
						</div>
						<div className='col'>
							<Image src='/images/awards-5.png' alt='logo' fluid />
						</div>
					</div>

					<br />

					<div className='row'>
						<div className='col'>
							<Image src='/images/awards-6.png' alt='logo' fluid />
						</div>
						<div className='col'>
							<Image src='/images/awards-7.png' alt='logo' fluid />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AwardsAndCertificatesScreen
