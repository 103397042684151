import React, { useEffect } from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const P3 = () => {
	useEffect(() => window.scrollTo(0, 0), [])

	return (
		<div className='container py-5'>
			<div className='row'>
				<div className='col'>
					<Image src='/images/home/7.png' alt='logo' fluid />
				</div>
				<div className='col'>
					<h4 className='text-center my-3'>Olive oil</h4>
					<h1 className='text-center'>FLAVORED EXTRA VIRGIN</h1>
					<hr style={{ width: '100px' }} />
					<h4 className='text-center'>Spicy butter with character</h4>
				</div>
				<div className='col'>
					<Image src='/images/home/7.png' alt='logo' fluid />
				</div>
			</div>

			<br />
			<br />
			<hr style={{ width: '100px' }} />

			<div className='row row-cols-1 row-cols-sm-1 row-cols-md-2 g-4 justify-content-center'>
				<div className='col mt-5'>
					<Image src='/images/home-9.png' alt='logo' fluid />
				</div>
				<div className='col mt-5'>
					<br />
					<br />
					<h6>
						Acidity &#60; 0.45%
						<br />
						<br />
						Volume 250 ml.
					</h6>
					<br />
					<ul>
						<li className='pt-3'>Spicy butter with character.</li>

						<li className='pt-4'>
							Premium oil of the first cold pressing of the highest quality with the
							addition of exclusively natural ingredients - lemon, pepper, garlic.
							Aromatic oil will add spice to any dish, make the taste more interesting
							and brighter.
						</li>

						<li className='pt-4'>
							Suitable for dressing salads, fish and meat dishes, pizza, preparing
							original sauces and marinade.
						</li>
					</ul>
				</div>
			</div>
			<br />
			<br />

			<section className='py-5' style={{ backgroundColor: '#EEEDDA' }}>
				<h1 className='text-center'>PRODUCTS</h1>

				<div className='text-center'>
					<hr style={{ width: '100px', backgroundColor: '#000000' }} />
				</div>

				<div className='container'>
					<div className='row'>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/organic-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-7.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Organic Extra Virgin
									</h5>
								</div>
							</Link>
						</div>

						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-8.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/flavored-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-9.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Flavored Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-10.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Virgin
									</h5>
								</div>
							</Link>
						</div>
					</div>

					<br />
					<br />
					<br />
				</div>
			</section>
		</div>
	)
}

export default P3
