import React from 'react'
import { Image } from 'react-bootstrap'

const ExportFromTunisiaScreen = () => {
	return (
		<>
			<div className='myContainer'>
				<Image
					src='/images/export-1.png'
					alt='logo'
					fluid
					className='w-100'
					style={{ minHeight: '250px' }}
				/>
				{/* <h1 class='centered' style={{ fontSize: '4vw' }}>
					TATHANDFROM - LANDDER PO ETOFROMPORTAT
				</h1> */}
			</div>

			<br />
			<br />

			<div className='container'>
				<h1 className='text-center'>
					LEADER IN THE EXPORT OF <br /> ORGANIC OLIVE OIL
				</h1>
				<hr style={{ width: '100px' }} />

				<p className='text-center' style={{ fontSize: '1.1rem' }}>
					Each major player in the international olive oil market has its own
					<br />
					characteristics. Tunisia has its advantage
				</p>

				<br />
				<br />
				<br />

				<p className='text-center'>
					Tunisia has the widest range of organic products. State regulation in the field
					of organic agricultural
					<br />
					production and support for the olive sector have brought Tunisia to a leading
					position.
				</p>

				<p className='text-center'>
					According to the Food&nbsp;
					<span style={{ color: '#C1D18B' }}>
						and Agriculture Organization of the United Nations (FAO ), “ Tunisia
						produces three
						<br />
						times more certified organic olive oil than Spain.
					</span>
					&nbsp;»
				</p>

				<p className='text-center'>
					The Center for Promoting Imports from Developing Countries CBI, which has
					existed under the Ministry of Foreign Affairs of the Netherlands since 1971,
					published on its official website on October 8, 2020 an analytical article
					<span style={{ color: '#C1D18B' }}>
						&nbsp;“Entering the European olive oil market ”
					</span>
					. Analyzing the roles of the main players in the olive oil market, the authors
					of the article assess the role of Tunisia as "the leading olive oil producing
					country outside Europe", noting that under favorable climatic conditions,
					Tunisia can become the second largest producer of olive oil in the world, and in
					terms of production organic oil Tunisia is already the largest exporter to the
					world market.
				</p>

				<br />
				<br />

				<div className='text-center'>
					{/* <Image src='/images/export-2.png' alt='logo' fluid /> */}
					<Image src='/images/home/3.png' alt='logo' fluid className='w-100' />
				</div>

				<br />
				<br />

				<h4 className='text-center'>Tunisia in the world ranking</h4>

				<br />
				<br />

				<h5 className='text-center'> 1st place in the world</h5>
				<p className='text-center'>
					in terms of exports of the purest olive oil (ORGANIC) with an acidity of up to
					0.3%
					<br />
					for the production of olive oil outside the EU
					<br />
					in terms of oil production per capita
				</p>

				<br />

				<h5 className='text-center'>2nd place in the world</h5>
				<p className='text-center'>
					in terms of the size of olive plantations
					<br />
					in terms of exports of olive oil to the EU countries
				</p>

				<br />

				<h5 className='text-center'> 3rd in the world</h5>
				<p className='text-center'>in terms of olive oil exports</p>

				<br />

				<h5 className='text-center'>4th in the world</h5>
				<p className='text-center'>in terms of olive oil production (10%)</p>

				<br />

				<h5 className='text-center'>5th place in the world in terms</h5>
				<p className='text-center'>of the start of olive cultivation</p>

				<br />

				<h5 className='text-center'>6th in the world</h5>
				<p className='text-center'>in terms of the number of harvested olives</p>

				<br />
				<br />

				<p className='text-center'>
					Tunisia ranks
					<span style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#000' }}>
						&nbsp;2nd&nbsp;
					</span>
					in the world in terms of the area of ​​olive plantations and only
					<span style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#000' }}>
						&nbsp;6th&nbsp;
					</span>
					in the world in terms of the number of olives harvested for oil production. This
					means that in Tunisia they are focused on quality, and not on the volume of oil
					output. The quality of the oil is ensured by 100% hand-picked olives and the
					absence of chemicals.
				</p>

				<br />
				<br />

				<h4 className='text-center'>Export of olive oil to EU countries</h4>

				<br />
				<br />

				<div className='row text-center'>
					<div className='col'>
						<Image src='/images/export-3.png' alt='logo' fluid />
						<br />
						<br />
						<p>
							The volume of exports of Tunisia to the EU countries for 2019-2020, tons
							/ year
						</p>
					</div>
					<div className='col'>
						<Image src='/images/export-4.png' alt='logo' fluid />
						<br />
						<br />
						<p>The volume of exports of EU countries for 2019-2020, tons / year</p>
					</div>
				</div>

				<br />
				<br />

				<p className='text-center'>
					Tunisia exports its best olive oil
					<span style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#000' }}>
						&nbsp;to Spain - 61.3%, to Italy - 33.5%, to Portugal - 0.94%&nbsp;
					</span>
				</p>

				<br />

				<p className='text-center'>
					It is worth noting that 95% of olive oil from Tunisia is exported in bulk and
					only 5% in bottles. Thus, Tunisian oil gets into the bottles of Spanish and
					Italian producers!
				</p>

				<br />

				<p className='text-center'>
					The total export of Tunisian olive oil in 2019 amounted to 170 thousand tons.
					Europe is the main export destination for Tunisian olive oil (nearly 80%),
					followed by the United States. European Union countries imported 121 thousand
					tons of olive oil from Tunisia in 2019. Of this amount, 54% was Extra Virgin
					Olive Oil, 34% extra virgin olive oil, 9% pomace olive oil and 3% other olive
					oils.
				</p>
			</div>
			<br />
			<br />
		</>
	)
}

export default ExportFromTunisiaScreen
