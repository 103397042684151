import React from 'react'
import { Image } from 'react-bootstrap'

const Production = () => {
	return (
		<>
			<div className='myContainer'>
				<Image
					src='/images/production/12.png'
					alt='logo'
					fluid
					className='w-100'
					style={{ minHeight: '250px' }}
				/>
				<span className='centered text-white'>
					{/* <h1 className='text-white '>
						<b>Domaine Beldi</b>
					</h1> */}
					{/* <h1 style={{ color: '#7E8C51' }}>
						<b>Domaine Beldi</b>
					</h1> */}
					{/* <p>
							At hem ande toRaboutetWithl aboutwithaboutbennaboutWithtb uhtal
							Dainathte RahbandRatbWithl
						</p> */}
				</span>
			</div>

			<section className='container py-5' style={{ backgroundColor: '#EEEDDA' }}>
				<h1 className='text-center'>PRODUCTION SECRETS</h1>
				<hr style={{ width: '100px' }} />

				<br />

				<h4 className='text-center'>
					How Domaine Beldi gained international <br /> recognition for quality
				</h4>

				<br />
				<br />

				<div className='text-center'>
					<p>
						The Beldi farming family produces olive oil from the finest hand-picked
						olives.
					</p>

					<p>
						For the production of Domaine Beldi olive oil, two varieties of the best
						Tunisian olives Chemlali and Chetoui were chosen, different in character and
						intensity of flavors, perfectly complementing each other.
					</p>

					<p>
						Both varieties of these olives contain a high amount of phenolic compounds
						(> 300 ppm), which guarantees the resistance of the oil produced from them
						to oxidation, and the low acidity ensures Domaine Beldi's strict adherence
						to traditional technology.
					</p>
				</div>

				<div className='row text-center'>
					<div className='col-12 col-sm-12 col-md-6 mt-4'>
						<Image src='/images/production/1.png' alt='logo' fluid />

						<br />
						<br />

						<p>
							<strong>Chemlali olives ,</strong>
							the most common variety in Tunisia, give the oil a light, soft, fruity
							note and a golden color.
						</p>
					</div>

					<div className='col-12 col-sm-12 col-md-6 mt-4'>
						<Image src='/images/production/2.png' alt='logo' fluid />

						<br />
						<br />

						<p>
							<strong>The Chetoui olive variety</strong>
							is revered by olive oil connoisseurs for its brighter character,
							pronounced note of green almonds and spicy bitterness.
						</p>
					</div>
				</div>

				<div className='row text-center'>
					<div className='col-12 col-sm-12 col-md-6 mt-4'>
						<Image src='/images/production/3.png' alt='logo' fluid />
					</div>

					<div className='col-12 col-sm-12 col-md-6 mt-4'>
						<Image src='/images/production/4.png' alt='logo' fluid />
					</div>
				</div>

				<br />
				<br />

				<div className='text-center'>
					<p>
						Olives are harvested exclusively by hand (100% hand-picked), and their
						transportation to the oil mill is carried out no more than 6 hours after
						they are harvested.
					</p>

					<p>
						Carefully preserving the traditions, taking care of the collection of fruits
						and their processing, the Beldi family created an oil of excellent quality,
						with excellent organoleptic characteristics and a wonderful delicate fruity
						taste. Due to the high demand for Domaine Beldi oil, it became possible to
						expand and modernize the production line.
					</p>
				</div>

				<br />
				<br />

				<Image className='w-100' src='/images/production/5.png' alt='logo' fluid />

				<br />
				<br />

				<div className='text-center'>
					<p>
						In 2000, a modern oil mill was installed, the best storage tanks for the
						finished product, a high-speed packaging line and an in-house laboratory to
						control the quality and acidity level of each batch of olive oil produced.
					</p>

					<p>
						The gentle cold pressing technology, not exceeding 27 C°, as well as
						stirring the olive paste at 22 C° for 50 minutes at ≤ 26 rpm, allows you to
						preserve all the beneficial properties of freshly squeezed olive juice:
						vitamins, beneficial unsaturated fatty acids and antioxidants, so necessary
						to maintain health and beauty.
					</p>

					<h5>
						Domaine Beldi produces only Extra Virgin and Cold Pressed Virgin oils,
						without any refining or deodorization methods.
					</h5>
				</div>

				<br />

				<div className='row'>
					<div className='col-12 col-sm-12 col-md-4 mt-4'>
						<Image className='w-100' src='/images/production/6.png' alt='logo' fluid />
					</div>
					<div className='col-12 col-sm-12 col-md-4 mt-4'>
						<Image className='w-100' src='/images/production/7.png' alt='logo' fluid />
					</div>
					<div className='col-12 col-sm-12 col-md-4 mt-4'>
						<Image className='w-100' src='/images/production/8.png' alt='logo' fluid />
					</div>
				</div>

				<br />
				<br />

				<p>
					Domaine Beldi products have received the prestigious Ecocert organic
					certification. Since then, the company has been growing, producing olive oil
					worthy of the highest awards and European recognition, as evidenced by the
					medals received in 2021 and 2022 at prestigious international competitions in
					New York, London and Canada.
				</p>

				<br />
				<br />

				<div className='text-center'>
					<Image src='/images/production/9.png' alt='logo' fluid />
					<br />
					<Image src='/images/production/10.png' alt='logo' fluid />
					<br />
					<br />
					<Image className='w-100' src='/images/production/11.png' alt='logo' fluid />
				</div>

				<br />
				<br />

				<div className='container'>
					<div className='row'>
						<div className='col-12 col-sm-12 col-md-4'>
							<br />

							<img
								src='/images/home-3.png'
								alt='err'
								style={{ width: '80px', height: '80px' }}
							/>

							<br />
							<br />
							<br />

							<h4 className='text-left'>Own olive plantations</h4>

							<br />

							<p className='text-left'>
								25,000 own olive trees
								<br />
								<br />
								The best varieties of olives Chemleli and Chetoui
								<br />
								<br />
								The ideal soil, climate and terrain ensure that the olives ripen
								evenly
								<br />
								<br />
								A statutory ban on the use of chemicals. fertilizers, pesticides and
								GMOs (Law No. 99-30: organic farming)
								<br />
								<br />
								1,800 tons of olives harvested annually
							</p>
						</div>

						<div className='col-12 col-sm-12 col-md-4'>
							<br />
							<img
								src='/images/home-4.png'
								alt='err'
								style={{ width: '80px', height: '80px' }}
							/>

							<br />
							<br />
							<br />

							<h4 className='text-left'>
								Compliance with production standards since 1881
							</h4>

							<br />

							<p className='text-left'>
								100% hand-picked olives (100% hand-picked)
								<br />
								<br />
								Selection of leaves and unusable fruits
								<br />
								<br />
								Pressing the oil on the day of the olive harvest
								<br />
								<br />
								Cold pressing (first cold press) at t° 27 С°
								<br />
								<br />
								Compliance with IOC and Codex Alimentarius standards
								<br />
								<br />
								Compliance with IOC and Codex Alimentarius standards
								<br />
								<br />
								Low acidity
								<br />
								<br />
								Own laboratory
								<br />
								<br />
								Personal control of the Family members, not the Corporation's
								managers
							</p>
						</div>

						<div className='col-12 col-sm-12 col-md-4'>
							<br />
							<img
								src='/images/home-5.png'
								alt='err'
								style={{ width: '80px', height: '80px' }}
							/>

							<br />
							<br />
							<br />

							<h4 className='text-left'>International recognition</h4>

							<br />

							<p className='text-left'>
								Stable premium quality
								<br />
								<br />
								Organic / Bio certificates: ECOCERT; AB; Euro-leaf; USDA Organic
								<br />
								<br />
								No heat treatment, deodorization and refining
								<br />
								<br />
								Awards at the international olive oil competitions 2021 - 2022.
								<br />
								<br />
								Constant direct deliveries to 7 countries of the world
								<br />
								<br />
								Constant direct deliveries to 7 countries of the world
							</p>
						</div>
					</div>
				</div>

				<br />
			</section>
		</>
	)
}

export default Production
