import React from 'react'
import { Image } from 'react-bootstrap'
import Categories from '../components/Categories'

const Product1Screen = () => {
	return (
		<div className='container pb-5'>
			<div className='row'>
				<div className='col-12 col-sm-12 col-md-8 col-lg-8'>
					<br />
					<br />
					<div className='geeks'>
						<Image src='/images/blog-1.png' alt='logo' fluid className='w-100' />
					</div>

					<br />

					<p>
						Olive trees and olive oil are an integral part of the history and culture of
						Tunisia. The climatic conditions of the country are ideal for growing olives
						used for oil production, as in some regions of the country the sun shines
						for more than 3,000 hours a year. The terrain is flat, without mountains,
						allowing the olives to receive a lot of evenly incoming sunlight.
					</p>
					<p>
						Tunisia has the highest density of olive plantations in the world, at around
						1.8 million hectares. It ranks second in the world in olive oil production
						after Europe and is a leader in the export of the purest organic olive oil.
					</p>
					<p>
						Tunisian producers are mainly represented by a small family business: family
						secrets and recipes are carefully kept and passed down from generation to
						generation. Most families have their own olive orchards and oil mills. The
						olives are carefully hand-picked and cold-pressed under strict hygienic
						conditions, guaranteeing the highest quality olive oil and meeting the
						strict regulations of the International Olive Committee IOOC.
					</p>

					<p>
						Olive oil, as an exported product, is of strategic importance to the
						Tunisian economy and is subject to strict regulations from international and
						national organizations (IOC, EC, INNORPI) to ensure that it meets global
						quality standards. Tunisian olive oil is internationally recognized and has
						received numerous awards from international olive oil competitions held in
						Los Angeles, Athens, Italy, London, New York and Japan. The oil has high
						organoleptic characteristics and is revered for its herbaceous-fruity,
						complex and harmonious taste; purity guaranteed by the standards of organic
						production, enshrined at the state level by the law on organic production
						99/30.
					</p>

					<p>
						Olive oil is an important ingredient in the country's gastronomy.
						<span style={{ color: '#C1D18B' }}>
							&nbsp;“In Tunisia, olive oil is so much more than just food ,”&nbsp;
						</span>
						says Dr. Sonda Laroussi, an olive oil expert from Sfax, a coastal city in
						central Tunisia. “It's our culture and our craftsmanship, and it's part of
						our daily lives. The history of olive oil is our own history.” “There are
						two main varieties of olives in Tunisia,” explains Dr. Laroussi. “Chetui
						comes from the north and has a very green and herbaceous flavor with hints
						of artichoke. This is a quality oil with character. Chemlali is softer,
						fruitier and has hints of green almonds. It goes with everything and doesn't
						dominate the dishes too much."
					</p>

					<p>
						The best evidence of the recognition of the unique taste of Tunisian olive
						oil is the
						<span style={{ color: '#C1D18B' }}>&nbsp;highest marks of&nbsp;</span> in
						Russia, we are recognized chefs. Tunisian olive oil is attracting more and
						more new consumers and is confidently sharing a place of honor on the
						shelves of gourmet food stores along with Italian and Spanish oils, as well
						as in the kitchens of Europe's most famous chefs.
					</p>
				</div>
				<div className='col-12 col-sm-12 col-md-4 col-lg-4'>
					<br />
					<br />
					<Categories />
				</div>
			</div>
		</div>
	)
}

export default Product1Screen
