import React from 'react'

const Footer = () => {
	return (
		<footer className='pt-5 pb-3' style={{ backgroundColor: '#2F2F2F', color: '#EEEDDA' }}>
			<div className='container'>
				<div className='row'>
					<div className='col-12 col-sm-12 col-md-4'>
						<br />

						<div>Société Mutuelle</div>
						<div>De Service Agricole</div>
						<div>ARDH EL KHIR DOMAINE BELDI </div>
					</div>
					<div className='col-12 col-sm-12 col-md-4'>
						<br />

						<div>Domaine Beldi</div>
						<div>Declarations of Conformity:</div>
						<div>EAEU N RU D-TN.RA01.V.51473/21</div>
						<div>EAEU N RU D-TN.RA01.V.51484/21</div>
					</div>
					<div className='col-12 col-sm-12 col-md-3'>
						<br />

						<div className='pb-2'>
							<i className='fa-solid fa-globe' style={{ color: '#7E8C51' }}></i>
							&nbsp;&nbsp;
							<a href='beldi.com.tn' style={{ color: '#EEEDDA' }}>
								beldi.com.tn
							</a>
						</div>
						<div className='pb-2'>
							<i className='fa-solid fa-envelope' style={{ color: '#7E8C51' }}></i>
							&nbsp;&nbsp;
							<a href='mailto: beldi@ardhelkhir.com.tn' style={{ color: '#EEEDDA' }}>
								beldi@ardhelkhir.com.tn
							</a>
						</div>
						<div className='pb-2 text-nowrap'>
							<i className='fa-solid fa-envelope' style={{ color: '#7E8C51' }}></i>
							&nbsp;&nbsp;
							<a
								href='mailto: smsa.ardhelkhir@gmail.com'
								style={{ color: '#EEEDDA' }}
							>
								smsa.ardhelkhir@gmail.com
							</a>
						</div>
						<div>
							<i className='fa-solid fa-phone' style={{ color: '#7E8C51' }}></i>
							&nbsp;&nbsp;
							<a href='tel: +21696794816' style={{ color: '#EEEDDA' }}>
								(+216) 96 794 816
							</a>
						</div>
					</div>
				</div>

				<p className='text-center text-muted mt-5'>
					© 2020 Copyright :{' '}
					<a
						href='https://www.facebook.com/tarak.guezguez'
						target='blank'
						className='text-muted'
					>
						GuezGuez Med.Tarek
					</a>
				</p>
			</div>
		</footer>
	)
}

export default Footer
