import React from 'react'

const LegislationScreen = () => {
	return (
		<div className='container pt-5'>
			<br />

			<h1 className='text-center'>
				LAW ON ORGANIC <br /> PRODUCTION
			</h1>
			<hr style={{ width: '100px' }} />

			<br />

			<p className='text-center' style={{ fontSize: '1.1rem' }}>
				The quality of Tunisian olive oil is guaranteed by the state. <br /> Organic
				Production Law 99-30
			</p>

			<br />
			<br />

			<p className='text-center'>
				One of the decisive actions taken by the government of Tunisia to develop and
				strengthen the national organic sector was the adoption of the law on organic
				production No. 99-30 of April 5, 1999.
			</p>

			<p className='text-center'>
				According to the Food and Agriculture Organization of the United Nations (Food and
				Agriculture Organization of the United Nati), Tunisia has become the first African
				country to legislate organic production.
			</p>

			<p className='text-center'>
				In accordance with this law, in Tunisia, special rules and requirements are
				established and controlled by the state, which are the same for all producers of
				agricultural products at all stages of the process: from harvesting, processing raw
				materials, production and further promotion of products.
			</p>

			<p className='text-center'>
				When developing law 99-30, the rules and standards of the EU for organic production,
				the regulatory requirements of the international federation for organic production
				IFOAM, the standards of the international food Codex Alimentarius were taken into
				account, thanks to which the Tunisian law became the equivalent of international
				standards and was approved by the European Union.
			</p>

			<p className='text-center'>
				The total export of Tunisian olive oil in 2019 amounted to 170 thousand tons. Europe
				is the main export destination for Tunisian olive oil (nearly 80%), followed by the
				United States. European Union countries imported 121 thousand tons of olive oil from
				Tunisia in 2019. Of this amount, 54% was Extra Virgin Olive Oil, 34% extra virgin
				olive oil, 9% pomace olive oil and 3% other olive oils.
			</p>
			<br />
		</div>
	)
}

export default LegislationScreen
