import React, { useEffect } from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const P1 = () => {
	useEffect(() => window.scrollTo(0, 0), [])

	return (
		<div className='container py-5'>
			<div className='row'>
				<div className='col'>
					<Image src='/images/home/7.png' alt='logo' fluid />
				</div>
				<div className='col'>
					<h4 className='text-center my-3'>Olive oil</h4>
					<h1 className='text-center'>ORGANIC EXTRA VIRGIN</h1>
					<hr style={{ width: '100px' }} />
					<h4 className='text-center'>
						For true connoisseurs and fans of healthy lifestyle
					</h4>
				</div>
				<div className='col'>
					<Image src='/images/home/7.png' alt='logo' fluid />
				</div>
			</div>

			<br />
			<br />
			<hr style={{ width: '100px' }} />

			<div className='row row-cols-1 row-cols-sm-1 row-cols-md-2 g-4 justify-content-center'>
				<div className='col mt-5'>
					<Image src='/images/home-7.png' alt='logo' fluid />
				</div>
				<div className='col mt-5'>
					<br />
					<br />
					<h6>
						Acidity &#60; 0.3%
						<br />
						<br />
						Volume 100, 250, 500, 750, 1000 ml.
					</h6>
					<br />
					<ul>
						<li className='pt-3'>Organic unrefined oil of the first cold pressing.</li>

						<li className='pt-4'>
							Category Premium. Environmentally friendly product. The best fruits are
							selected for its production.
						</li>

						<li className='pt-4'>
							Unique aroma of sunny ripe olives, mild taste with delicate herbal
							bitterness.
						</li>

						<li className='pt-4'>
							Harmony of taste and benefits, an abundance of vitamins, acids and
							polyphenols, powerful antioxidants that protect body cells from
							oxidation and aging.
						</li>

						<li className='pt-4'>
							Organic is an oil for those who choose to live in harmony with nature
							and accept its priceless gifts.
						</li>

						<li className='pt-4'>
							Environmental Compliance Certificate: Ecocert SA TN-BIO-001
						</li>

						<li className='pt-4'>
							Ideal for any kind of cooking. It is most useful as an element of
							dietary nutrition, a prophylactic in the healthy lifestyle system,
							modern doctors recommend Extra Virgin oil for both adults and children
							from 6 months .{' '}
						</li>
					</ul>
					<br />
					<br />
					<div className='text-center'>
						<div className='d-flex justify-content-center align-items-center '>
							<Image
								src='/images/awards/2.png'
								alt='logo'
								style={{ maxWidth: '100px' }}
							/>
							<Image
								src='/images/awards/1.png'
								alt='logo'
								style={{ maxWidth: '100px' }}
							/>
						</div>
						<br />
						<p>
							Domaine Beldi Extra Virgin Quality Awards
							<br />
							from CIOOC and LIOOC 2021
							<br />
							(Canada and London)
						</p>
					</div>
				</div>
			</div>
			<br />
			<br />

			<section className='py-5' style={{ backgroundColor: '#EEEDDA' }}>
				<h1 className='text-center'>PRODUCTS</h1>

				<div className='text-center'>
					<hr style={{ width: '100px', backgroundColor: '#000000' }} />
				</div>

				<div className='container'>
					<div className='row'>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/organic-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-7.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Organic Extra Virgin
									</h5>
								</div>
							</Link>
						</div>

						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-8.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/flavored-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-9.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Flavored Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-10.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Virgin
									</h5>
								</div>
							</Link>
						</div>
					</div>

					<br />
					<br />
					<br />
				</div>
			</section>
		</div>
	)
}

export default P1
