import React, { useEffect } from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const P4 = () => {
	useEffect(() => window.scrollTo(0, 0), [])

	return (
		<div className='container py-5'>
			<div className='row'>
				<div className='col'>
					<Image src='/images/home/7.png' alt='logo' fluid />
				</div>
				<div className='col'>
					<h4 className='text-center my-3'>Olive oil</h4>
					<h1 className='text-center'>VIRGIN</h1>
					<hr style={{ width: '100px' }} />
					<h4 className='text-center'>The perfect oil for every day</h4>
				</div>
				<div className='col'>
					<Image src='/images/home/7.png' alt='logo' fluid />
				</div>
			</div>

			<br />
			<br />
			<hr style={{ width: '100px' }} />

			<div className='row row-cols-1 row-cols-sm-1 row-cols-md-2 g-4 justify-content-center'>
				<div className='col mt-5'>
					<Image src='/images/home-10.png' alt='logo' fluid />
				</div>
				<div className='col mt-5'>
					<br />
					<br />
					<h6>
						Acidity &#60; 1.5%
						<br />
						<br />
						Volume 250, 500, 1000, 3000 ml.
					</h6>
					<br />
					<ul>
						<li className='pt-3'>
							Unrefined olive oil, also obtained by first cold pressing without the
							use of heat treatment and deodorization, a grade slightly lower than
							Extra Virgin and more acidic. The taste and aroma are less intense,
							softer.
						</li>

						<li className='pt-4'>
							The neutrality of taste allows the use of Virgin oil in all areas
							without limitation: in the preparation of cold and hot dishes, in
							cooking, and in cosmetology.
						</li>
					</ul>
				</div>
			</div>
			<br />
			<br />

			<section className='py-5' style={{ backgroundColor: '#EEEDDA' }}>
				<h1 className='text-center'>PRODUCTS</h1>

				<div className='text-center'>
					<hr style={{ width: '100px', backgroundColor: '#000000' }} />
				</div>

				<div className='container'>
					<div className='row'>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/organic-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-7.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Organic Extra Virgin
									</h5>
								</div>
							</Link>
						</div>

						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-8.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/flavored-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-9.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Flavored Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-10.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Virgin
									</h5>
								</div>
							</Link>
						</div>
					</div>

					<br />
					<br />
					<br />
				</div>
			</section>
		</div>
	)
}

export default P4
