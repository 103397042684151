import React from 'react'
import { Navbar, Container, NavDropdown, Nav, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Header = () => {
	return (
		<header style={{ backgroundColor: '#262626' }}>
			{/* <div className='container d-flex justify-content-end pt-3 pb-0'>
				<div>
					<i className='fa-solid fa-phone' style={{ color: '#7E8C51' }}></i>
					&nbsp;&nbsp;
					<a href='tel: 8 (931) 9-670-670' style={{ color: '#EEEDDA' }}>
						8 (931) 9-670-670
					</a>
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<div>
					<i className='fa-solid fa-envelope' style={{ color: '#7E8C51' }}></i>
					&nbsp;&nbsp;
					<a href='mailto: sales@organicolives.ru' style={{ color: '#EEEDDA' }}>
						sales@organicolives.ru
					</a>
				</div>
			</div> */}
			<Navbar
				variant='dark'
				expand='lg'
				collapseOnSelect
				className='py-2'
				style={{ backgroundColor: '#262626' }}
			>
				<Container>
					<LinkContainer to='/'>
						<Navbar.Brand>
							<Image
								src='/images/logo.png'
								alt='logo'
								fluid
								roundedCircle
								style={{ width: '60px', height: '60px' }}
							/>
						</Navbar.Brand>
					</LinkContainer>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ml-auto'>
							<LinkContainer to='/'>
								<Nav.Link>Home</Nav.Link>
							</LinkContainer>

							<LinkContainer to='/about'>
								<Nav.Link>About us</Nav.Link>
							</LinkContainer>

							<NavDropdown
								title='BRAND DOMAINE BELDI'
								id='basic-nav-dropdown'
								className='mr-0'
							>
								<LinkContainer to='/production'>
									<NavDropdown.Item>PRODUCTION</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to='/characteristics'>
									<NavDropdown.Item>OIL CHARACTERISTICS</NavDropdown.Item>
								</LinkContainer>
							</NavDropdown>

							<NavDropdown
								title='QUALITY ASSURANCE'
								id='basic-nav-dropdown'
								className='mr-0'
							>
								<LinkContainer to='/awards-and-certificates'>
									<NavDropdown.Item>AWARDS AND CERTIFICATES</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to='/legislation'>
									<NavDropdown.Item>LEGISLATION</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to='/export-from-tunisia'>
									<NavDropdown.Item>EXPORT FROM TUNISIA</NavDropdown.Item>
								</LinkContainer>
							</NavDropdown>

							<LinkContainer to='/cooperation'>
								<Nav.Link>Contact us</Nav.Link>
							</LinkContainer>

							<LinkContainer to='/representative'>
								<Nav.Link>our representative</Nav.Link>
							</LinkContainer>

							{/* <NavDropdown title='BLOG/RECIPES' id='basic-nav-dropdown'>
								<LinkContainer to='/blog'>
									<NavDropdown.Item>BLOG</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to='/recipes'>
									<NavDropdown.Item>RECIPES</NavDropdown.Item>
								</LinkContainer>
							</NavDropdown> */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	)
}

export default Header
