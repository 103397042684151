import React from 'react'

const CooperationScreen = () => {
	return (
		<div className='container py-5'>
			<br />
			<br />

			<h1 className='text-center'>CONTACT US</h1>
			<hr style={{ width: '100px' }} />

			<br />
			<br />

			<div className='d-flex justify-content-center'>
				<p>
					<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
						<i className='fa fa-map-marker' aria-hidden='true'></i>
						&nbsp;&nbsp;Address: Dhreaat Sbikha Kairouan CP 3194,TN
					</span>

					<br />
					<br />

					<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
						<i className='fa fa-phone' aria-hidden='true'></i>
						&nbsp;&nbsp;Phone: +216 96 794 816
					</span>

					<br />
					<br />

					<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
						<i className='fa fa-envelope' aria-hidden='true'></i>
						&nbsp;&nbsp;Email:beldi@ardhelkhir.com.tn
					</span>

					<br />
					<br />

					<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
						<i className='fa fa-envelope' aria-hidden='true'></i>
						&nbsp;&nbsp;Email:smsa.ardhelkhir@gmail.com
					</span>
				</p>
			</div>

			{/* <div className='row mb-5'>
				<div className='col-12 col-sm-12 col-md-6'>
					<br />
					<br />

					<p>
						<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
							<i className='fa fa-map-marker' aria-hidden='true'></i>
							&nbsp;&nbsp;Address: Dhreaat Sbikha Kairouan CP 3194,TN
						</span>

						<br />
						<br />

						<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
							<i className='fa fa-phone' aria-hidden='true'></i>
							&nbsp;&nbsp;Phone: +216 96 794 816
						</span>

						<br />
						<br />

						<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
							<i className='fa fa-envelope' aria-hidden='true'></i>
							&nbsp;&nbsp;Email:beldi@ardhelkhir.com.tn
						</span>

						<br />
						<br />

						<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
							<i className='fa fa-envelope' aria-hidden='true'></i>
							&nbsp;&nbsp;Email:smsa.ardhelkhir@gmail.com
						</span>
					</p>
				</div>
				<div className='col-12 col-sm-12 col-md-6'>
					<br />
					<br />
					<form>
						<div className='form-group'>
							<input
								type='text'
								className='form-control'
								placeholder='Name*'
								style={{ border: '1px solid #B9B9AA' }}
							/>
						</div>

						<div className='form-group'>
							<input
								type='email'
								className='form-control'
								aria-describedby='emailHelp'
								placeholder='Email*'
								style={{ border: '1px solid #B9B9AA' }}
							/>
						</div>

						<div className='form-group'>
							<input
								type='text'
								className='form-control'
								placeholder='Message subject*'
								style={{ border: '1px solid #B9B9AA' }}
							/>
						</div>

						<div className='form-group'>
							<input
								type='text'
								className='form-control'
								placeholder='Phone*'
								style={{ border: '1px solid #B9B9AA' }}
							/>
						</div>

						<div className='form-group'>
							<textarea
								className='form-control'
								rows='5'
								placeholder='Message text*'
								style={{ border: '1px solid #B9B9AA' }}
							></textarea>
						</div>

						<div className='text-right mt-4'>
							<button
								className='btn'
								style={{ backgroundColor: '#78876B', color: '#FFFFFF' }}
							>
								SEND
							</button>
						</div>
					</form>
				</div>
			</div> */}
		</div>
	)
}

export default CooperationScreen
