import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const HomeScreen = () => {
	return (
		<>
			<section
				style={{
					backgroundColor: '#2F2F2F',
					backgroundImage: `url("/images/home/1.png")`,
					height: '1000px',
				}}
				className='h-100'
			>
				<div className='px-0'>
					<Image
						src='/images/home/1.png'
						alt='err'
						style={{ width: '100%', height: '100%' }}
					/>
					<Image
						src='/images/home/2.png'
						alt='err'
						style={{ width: '100%', height: '100%' }}
					/>
				</div>
			</section>

			<section className='py-5' style={{ backgroundColor: '#2F2F2F' }}>
				<h1 className='text-center' style={{ color: '#CFCEBE' }}>
					RELIABLE MANUFACTURER
					<br />
					FROM TUNISIA
				</h1>

				<div className='text-center'>
					<hr style={{ width: '100px', backgroundColor: '#7E8C51' }} />
				</div>

				<h6 className='container text-center mt-5' style={{ color: '#C9D5CA' }}>
					Since 1881, the Beldi farming family has been producing
					<br />
					<br />
					olive oil from
					<br />
					<br />
					100% hand-picked olives from their own olive trees.
				</h6>

				<br />
				<br />

				<div className='container'>
					<div className='row'>
						<div className='col-12 col-sm-12 col-md-6 text-center'>
							<Image className='mb-5' src='/images/home-1.png' alt='err' />
						</div>
						<div className='col-12 col-sm-12 col-md-6'>
							<p style={{ color: '#C9D5CA' }}>
								Carefully preserving the traditions, taking care of the collection
								of fruits and their processing, the Beldi family created an oil of
								excellent quality, with excellent organoleptic characteristics and a
								wonderful delicate fruity taste.
								<br />
								<br />
								The reputation of the Beldi family has long been a guarantor of
								quality and a symbol of fidelity to traditions in their homeland.
								<br />
								<br />
								Tens of thousands of our own olive trees, grown with care and love
								in accordance with the standards of organic farming, allow us to
								harvest more than one and a half thousand tons of olives of the best
								Chemlali and Chetoui varieties annually.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section style={{ backgroundColor: '#2F2F2F' }}>
				<div className='container-xl px-0'>
					<Image
						src='/images/home-2.png'
						alt='err'
						style={{ width: '100%', height: '300px' }}
					/>
				</div>
			</section>

			<section className='py-5 ' style={{ backgroundColor: '#2F2F2F' }}>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-sm-12 col-md-4'>
							<br />

							<Image
								src='/images/home-3.png'
								alt='err'
								style={{ width: '80px', height: '80px' }}
							/>

							<br />
							<br />
							<br />

							<h4 className='text-left' style={{ color: '#C9D5CA' }}>
								Own olive plantations
							</h4>

							<br />

							<p className='text-left' style={{ color: '#C9D5CA' }}>
								25,000 own olive trees
								<br />
								<br />
								The best varieties of olives Chemleli and Chetoui
								<br />
								<br />
								The ideal soil, climate and terrain ensure that the olives ripen
								evenly
								<br />
								<br />
								A statutory ban on the use of chemicals. fertilizers, pesticides and
								GMOs (Law No. 99-30: organic farming)
								<br />
								<br />
								1,800 tons of olives harvested annually
							</p>
						</div>

						<div className='col-12 col-sm-12 col-md-4'>
							<br />
							<Image
								src='/images/home-4.png'
								alt='err'
								style={{ width: '80px', height: '80px' }}
							/>

							<br />
							<br />
							<br />

							<h4 className='text-left' style={{ color: '#C9D5CA' }}>
								Compliance with production standards since 1881
							</h4>

							<br />

							<p className='text-left' style={{ color: '#C9D5CA' }}>
								100% hand-picked olives (100% hand-picked)
								<br />
								<br />
								Selection of leaves and unusable fruits
								<br />
								<br />
								Pressing the oil on the day of the olive harvest
								<br />
								<br />
								Cold pressing (first cold press) at t° 27 С°
								<br />
								<br />
								Compliance with IOC and Codex Alimentarius standards
								<br />
								<br />
								Compliance with IOC and Codex Alimentarius standards
								<br />
								<br />
								Low acidity
								<br />
								<br />
								Own laboratory
								<br />
								<br />
								Personal control of the Family members, not the Corporation's
								managers
							</p>
						</div>

						<div className='col-12 col-sm-12 col-md-4'>
							<br />
							<Image
								src='/images/home-5.png'
								alt='err'
								style={{ width: '80px', height: '80px' }}
							/>

							<br />
							<br />
							<br />

							<h4 className='text-left' style={{ color: '#C9D5CA' }}>
								International recognition
							</h4>

							<br />

							<p className='text-left' style={{ color: '#C9D5CA' }}>
								Stable premium quality
								<br />
								<br />
								Organic / Bio certificates: ECOCERT; AB; Euro-leaf; USDA Organic
								<br />
								<br />
								No heat treatment, deodorization and refining
								<br />
								<br />
								Awards at the international olive oil competitions 2021 - 2022.
								<br />
								<br />
								Constant direct deliveries to 7 countries of the world
								<br />
								<br />
								Constant direct deliveries to 7 countries of the world
							</p>
						</div>
					</div>
				</div>
			</section>

			<section style={{ backgroundColor: '#2F2F2F' }}>
				<div className='px-0'>
					<Image
						src='/images/home-6.png'
						alt='err'
						style={{ width: '100%', maxHeight: '450px' }}
					/>
				</div>
			</section>

			<section className='py-5' style={{ backgroundColor: '#EEEDDA' }}>
				<h1 className='text-center'>PRODUCTS</h1>

				<div className='text-center'>
					<hr style={{ width: '100px', backgroundColor: '#000000' }} />
				</div>

				<div className='container'>
					<div className='row'>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/organic-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-7.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Organic Extra Virgin
									</h5>
								</div>
							</Link>
						</div>

						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-8.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/flavored-extra-virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-9.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Flavored Extra Virgin
									</h5>
								</div>
							</Link>
						</div>
						<div className='col-6 col-sm-6 col-md-3 mt-5'>
							<Link to='/virgin'>
								<div className='h-100 '>
									<Image
										src='/images/home-10.png'
										alt='err'
										className='w-100 h-100'
									/>

									<h5 className='text-center mt-3' style={{ color: '#839057' }}>
										Virgin
									</h5>
								</div>
							</Link>
						</div>
					</div>

					<br />
					<br />
					<br />
				</div>
			</section>

			<section>
				<Image
					src='/images/home-11.png'
					alt='err'
					style={{ width: '100%', height: '100%' }}
				/>
			</section>

			<section style={{ backgroundColor: '#7E8C51' }}>
				<br />
				<h1 className='text-center' style={{ color: '#CFCEBE' }}>
					BLOG / RECIPES
				</h1>

				<div className='text-center'>
					<hr style={{ width: '100px', backgroundColor: '#7E8C51' }} />
				</div>

				<br />
				<br />

				<div className='container'>
					<div className='row'>
						<div className='col-12 col-sm-12 col-md-4'>
							<div>
								<Image
									src='/images/blog-1.png'
									className='rounded w-100 h-100'
									alt='err'
								/>

								<h5 className='text-center text-white mt-3'>Tunisian olive oil</h5>

								<p className='text-dark pt-2'>
									Olive trees and olive oil are an integral part of the history
									and culture of Tunisia. The climatic conditions of the country
									are ideal for...
								</p>
							</div>
						</div>
						<div className='col-12 col-sm-12 col-md-4'>
							<div>
								<Image
									src='/images/blog-2.png'
									className='rounded w-100 h-100'
									alt='err'
								/>

								<h5 className='text-center text-white mt-3'>
									Homemade olive oil tasting
								</h5>

								<p className='text-dark pt-2'>
									For tasting, you will need glasses or cups for each olive oil
									sample. Professional tasters use blue wine glasses to...
								</p>
							</div>
						</div>
						<div className='col-12 col-sm-12 col-md-4'>
							<div>
								<Image
									src='/images/blog-3.png'
									className='rounded w-100 h-100'
									alt='err'
								/>

								<h5 className='text-center text-white mt-3'>
									Politics and Tunisian oil
								</h5>

								<p className='text-dark pt-2'>
									Tunisia is one of the largest olive oil producers outside the
									European Union and the world's largest exporter of organic olive
									oil.
								</p>
							</div>
						</div>
					</div>
				</div>

				<br />
				<br />
				<br />
				<br />
			</section>
		</>
	)
}

export default HomeScreen
