import React from 'react'

const Representative = () => {
	return (
		<div className='container py-5'>
			<br />
			<br />

			<h1 className='text-center'>our representative</h1>
			<hr style={{ width: '100px' }} />

			<div className='text-center my-5'>
				<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
					&nbsp;&nbsp;St. Petersburg
				</span>

				<br />

				<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
					&nbsp;&nbsp;Invent-service LLC, TIN 7841005923
				</span>

				<br />

				<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
					&nbsp;&nbsp;Importer, exclusive representative of Domaine Beldi in Russia.
				</span>

				<br />
				<br />
				<hr style={{ width: '250px' }} />
				<br />

				<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
					<i className='fa fa-globe' aria-hidden='true'></i>
					&nbsp;&nbsp;organicolivs.com
				</span>

				<br />
				<br />

				<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
					<i className='fa fa-envelope' aria-hidden='true'></i>
					&nbsp;&nbsp;sales@organicolives.ru
				</span>

				<br />
				<br />

				<span className='font-weight-bold' style={{ fontSize: '1.1rem' }}>
					<i className='fa fa-phone' aria-hidden='true'></i>
					&nbsp;&nbsp;8 (931) 9-670-670
				</span>
			</div>
		</div>
	)
}

export default Representative
