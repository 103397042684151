import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Categories from '../components/Categories'

const BlogScreen = () => {
	return (
		<div className='container pb-5'>
			<div className='row'>
				<div className='col-12 col-sm-12 col-md-8 col-lg-8'>
					<div>
						<br />
						<br />

						<div className='geeks'>
							<Image src='/images/blog-1.png' alt='logo' fluid className='w-100' />
						</div>

						<h5 className='mt-4'>Tunisian olive oil</h5>

						<hr style={{ width: '100px', marginLeft: 0 }} />

						<p>
							Olive trees and olive oil are an integral part of the history and
							culture of Tunisia. The climatic conditions of the country are ideal for
							growing olives used for oil production, since in some regions of the
							country the sun
						</p>

						<Link to='/tunisian-olive-oil'>
							<button
								className='btn'
								style={{ backgroundColor: '#B6CA77', color: '#FFFFFF' }}
							>
								READ MORE...
							</button>
						</Link>
					</div>

					<div>
						<br />
						<br />

						<div className='geeks'>
							<Image src='/images/blog-2.png' alt='logo' fluid className='w-100' />
						</div>

						<h5 className='mt-4'>Homemade olive oil tasting</h5>

						<hr style={{ width: '100px', marginLeft: 0 }} />

						<p>
							For tasting, you will need glasses or cups for each olive oil sample.
							Professional tasters use blue glasses to ignore the color of the oil.
							this criterion is not included in
						</p>

						<Link to='/tunisian-olive-oil'>
							<button
								className='btn'
								style={{ backgroundColor: '#B6CA77', color: '#FFFFFF' }}
							>
								READ MORE...
							</button>
						</Link>
					</div>

					<div>
						<br />
						<br />

						<div className='geeks'>
							<Image src='/images/blog-3.png' alt='logo' fluid className='w-100' />
						</div>

						<h5 className='mt-4'>Politics and Tunisian oil</h5>

						<hr style={{ width: '100px', marginLeft: 0 }} />

						<p>
							Tunisia is one of the largest olive oil producers outside the European
							Union and the world's largest exporter of organic olive oil. However,
							global awareness of Tunisian oil is relatively low due to
						</p>

						<Link to='/tunisian-olive-oil'>
							<button
								className='btn'
								style={{ backgroundColor: '#B6CA77', color: '#FFFFFF' }}
							>
								READ MORE...
							</button>
						</Link>
					</div>
				</div>
				<div className='col-12 col-sm-12 col-md-4 col-lg-4'>
					<br />
					<br />
					<Categories />
				</div>
			</div>
		</div>
	)
}

export default BlogScreen
