import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import AboutScreen from './screens/AboutScreen'
import BlogScreen from './screens/BlogScreen'
import RecipesScreen from './screens/RecipesScreen'
import CooperationScreen from './screens/CooperationScreen'
import Product1Screen from './screens/Product1Screen'
import AwardsAndCertificatesScreen from './screens/AwardsAndCertificatesScreen'
import LegislationScreen from './screens/LegislationScreen'
import ExportFromTunisiaScreen from './screens/ExportFromTunisiaScreen'
import HomeScreen from './screens/HomeScreen'
import Production from './screens/Production'
import Characteristics from './screens/Characteristics'
import Representative from './screens/Representative'
import P1 from './screens/products/P1'
import P2 from './screens/products/P2'
import P3 from './screens/products/P3'
import P4 from './screens/products/P4'

const App = () => {
	return (
		<Router>
			<Header />
			<main style={{ backgroundColor: '#EEEDDA' }}>
				<Routes>
					<Route path='/' element={<HomeScreen />} />

					<Route path='/about' element={<AboutScreen />} />

					<Route
						path='/awards-and-certificates'
						element={<AwardsAndCertificatesScreen />}
					/>
					<Route path='/legislation' element={<LegislationScreen />} />
					<Route path='/export-from-tunisia' element={<ExportFromTunisiaScreen />} />

					<Route path='/cooperation' element={<CooperationScreen />} />

					<Route path='/blog' element={<BlogScreen />} />
					<Route path='/recipes' element={<RecipesScreen />} />
					<Route path='/tunisian-olive-oil' element={<Product1Screen />} />

					<Route path='/production' element={<Production />} />
					<Route path='/characteristics' element={<Characteristics />} />

					<Route path='/representative' element={<Representative />} />

					<Route path='/organic-extra-virgin' element={<P1 />} />
					<Route path='/extra-virgin' element={<P2 />} />
					<Route path='/flavored-extra-virgin' element={<P3 />} />
					<Route path='/virgin' element={<P4 />} />
				</Routes>
			</main>
			<Footer />
		</Router>
	)
}

export default App
