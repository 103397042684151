import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Categories from '../components/Categories'

const RecipesScreen = () => {
	return (
		<div className='container pb-5'>
			<div className='row'>
				<div className='col-12 col-sm-12 col-md-8 col-lg-8'>
					<br />
					<br />

					<div className='geeks'>
						<Image src='/images/recipes-1.png' alt='logo' fluid className='w-100' />
					</div>

					<h5 className='mt-4'>Cream soup with broccoli, spinach and croutons</h5>

					<hr style={{ width: '100px', marginLeft: 0 }} />

					<p>
						Cook? Fry? Or for a couple? Although all forms of cooking result in some
						loss of vitamins in vegetables, steaming is generally considered to be one
						of the best ways to cook vegetables.
					</p>

					<Link to='/tunisian-olive-oil'>
						<button
							className='btn'
							style={{ backgroundColor: '#B6CA77', color: '#FFFFFF' }}
						>
							READ MORE...
						</button>
					</Link>
				</div>
				<div className='col-12 col-sm-12 col-md-4 col-lg-4'>
					<br />
					<br />
					<Categories />
				</div>
			</div>
		</div>
	)
}

export default RecipesScreen
