import React from 'react'
import { Image } from 'react-bootstrap'

const Characteristics = () => {
	return (
		<>
			<section style={{ backgroundColor: '#E5E5E5' }}>
				<div className='myContainer'>
					<Image
						src='/images/characteristics/8.png'
						alt='logo'
						fluid
						className='w-100'
						style={{ minHeight: '250px' }}
					/>
					<span className='centered text-white'>
						{/* <h1 className='text-white '>OPRODATTOTE</h1> */}
						{/* <h1 style={{ color: '#7E8C51' }}>
							<b>Domaine Beldi</b>
						</h1> */}
						{/* <p>
							At hem ande toRaboutetWithl aboutwithaboutbennaboutWithtb uhtal
							Dainathte RahbandRatbWithl
						</p> */}
					</span>
				</div>

				<div className='container pb-5'>
					<div className='row text-center'>
						<div className='col-12 col-sm-12 col-md-4 mt-5'>
							<div>
								<Image src='/images/characteristics/1.png' alt='logo' fluid />
								<h5 className='mt-3'>Free of GMOs and trans fats</h5>
								<p>
									Trans fats are the most harmful type of fat found in food. They
									are produced from vegetable fats using hydrogenation. The body
									does not absorb trans fats at all.
								</p>

								<Image
									className='mt-5'
									src='/images/characteristics/2.png'
									alt='logo'
									fluid
								/>
								<h5 className='mt-3'>Contains vitamins: A, E, D, K, B4</h5>
								<p>
									Vitamins contribute to the normal growth of cells and the
									development of the whole organism. Vitamins play an important
									role in maintaining the body's immune responses, ensuring its
									resistance to adverse environmental factors.
								</p>

								<Image
									className='mt-5'
									src='/images/characteristics/3.png'
									alt='logo'
									fluid
								/>
								<h5 className='mt-3'>High content of oleic acid (> 72%)</h5>
								<p>
									Reduces the level of bad cholesterol, lowers blood pressure, has
									a beneficial effect on the liver, improves the functioning of
									the brain and the immune system.
								</p>
							</div>
						</div>

						<div className='col-12 col-sm-12 col-md-4 mt-5'>
							<Image
								className='mt-5'
								src='/images/characteristics/centerimage.png'
								alt='logo'
								fluid
							/>
						</div>

						<div className='col-12 col-sm-12 col-md-4 mt-5'>
							<div>
								<Image src='/images/characteristics/4.png' alt='logo' fluid />
								<h5 className='mt-3'>Rich in polyphenols</h5>
								<p>
									Polyphenols are plant-based antioxidants that act as fuel for
									beneficial bacteria.
								</p>

								<Image
									className='mt-5'
									src='/images/characteristics/5.png'
									alt='logo'
									fluid
								/>
								<h5 className='mt-3'>
									The optimal ratio of omega-6 / omega-3 is 1:13
								</h5>
								<p>
									Omega-3s provide the body with energy and are used to form
									signaling molecules that support the cardiovascular, pulmonary,
									immune and endocrine systems.
								</p>

								<Image
									className='mt-5'
									src='/images/characteristics/6.png'
									alt='logo'
									fluid
								/>
								<h5 className='mt-3'>Harmonious taste</h5>
								<p>
									For the production of olive oil, two varieties of the best
									Tunisian olives Chemlali and Chetoui were chosen, different in
									character and intensity of flavors, perfectly complementing each
									other.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className='container text-center'>
					<Image className='mt-5' src='/images/characteristics/7.png' alt='logo' fluid />
					<h3 className='mt-3'>OIL ADVANTAGE</h3>
					<h1 className='mb-4'>DOMAIN BELDI</h1>

					<p>
						The reputation of the Beldi family has long been a guarantor of quality and
						a symbol of fidelity to traditions in their homeland.
					</p>

					<p>
						Tens of thousands of our own olive trees, grown with care and love in
						accordance with the standards of organic farming, allow us to harvest more
						than one and a half thousand tons of olives of the best Chemlali and Chetoui
						varieties annually.
					</p>

					<p>
						Chemlali olives, the most common variety in Tunisia, give the oil a light,
						soft, fruity note and a golden color. Chetoui is also revered by olive oil
						connoisseurs for its characteristic green almond note and tangy bitterness.
					</p>

					<p>
						The blend of these two varieties, mutually complementary, allows you to
						fully feel and enjoy the harmonious taste of Extra Virgin Domain Beldi oil.
					</p>

					<p>
						Gentle cold pressing technology, not exceeding 27 degrees, allows you to
						save all the beneficial properties of freshly squeezed olive juice:
						vitamins, beneficial unsaturated fatty acids and antioxidants, so necessary
						for maintaining health and beauty.
					</p>

					<p>This oil, like fine wine, is for true drink connoisseurs.</p>
				</div>

				<br />
				<br />
			</section>
		</>
	)
}

export default Characteristics
