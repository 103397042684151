import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Calendar from 'react-calendar'

const Categories = () => {
	const [date, setDate] = useState(new Date())

	return (
		<div style={{ backgroundColor: '#7E8C51', color: '#FFFFFF' }}>
			<div className='px-5 pt-5 pb-2'>
				<h6 className='text-white'>CATEGORIES</h6>
				<ul className='px-4 py-2'>
					<li>
						<Link to='/blog' className='text-white'>
							Blog
						</Link>
					</li>
					<li>
						<Link to='/recipes' className='text-white'>
							Recipes
						</Link>
					</li>
				</ul>

				<hr />
				<br />

				<h6 className='text-white'>CALENDAR</h6>
			</div>

			<Calendar className='text-nowrap w-100 myCalendar' onChange={setDate} value={date} />

			<div className='px-5 py-2'>
				<hr />
				<br />

				<h6 className='text-white'>THE LAST NOTES</h6>
				<ul className='px-4 py-2'>
					<li>
						<Link to='/' className='text-white'>
							Tunisian olive oil
						</Link>
					</li>
					<li>
						<Link to='/' className='text-white'>
							Homemade olive oil tasting
						</Link>
					</li>
					<li>
						<Link to='/' className='text-white'>
							Politics and Tunisian oil
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Categories
